<template>
    <div class="p-4 pt-10 md:pt-16">
        <div v-if="!validate" class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto">
            <p class="font-bold text-2xl mb-2">Dernière étape !</p>
            <p class="text-fonctGrey mb-6">Entrez les informations suivantes pour finaliser la création de votre compte</p>
            <RegisterValidateForm :validate="validate" @validateUpdate="validate = $event"/>  
        </div>
        <div v-else class="bg-white border border-innerBorder rounded-2xl p-4 lg:p-8 md:w-2/3 xl:w-1/2 mx-auto ">
            <div class="flex flex-col items-center space-y-6 mb-6">
                <p class="font-bold text-2xl mb-2">Votre compte est prêt</p>
                <img src="@/assets/svg/registerSuccess.svg" class="w-72 md:w-328 h-48 md:h-220">
            </div>
            <router-link to="/login"><m-button :classes="'text-white font-bold text-sm sm:text-base bg-primary rounded-lg p-2 w-full'" :text="'Connectez-vous à présent'"></m-button></router-link>
        </div>
    </div>
</template>

<script>
import RegisterValidateForm from './forms/RegisterValidateForm'
export default {
    data() {
        return {
            validate: false
        }
    },
    components: {
        RegisterValidateForm
    },
    computed: {
        
    },
    methods: {
        
    },
}
</script>

<style scoped>

</style>